<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      ref="mySidebar"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ sidebarTitle }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSideBar()"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <!-- <b-col md="12" v-if="adding">
            <b-form-group
              label="Subject *"
              invalid-feedback="Subject is required."
              ref="subject"
            >
              <b-form-textarea
                ref="subject"
                placeholder="Enter subjects seperated by comma"
                v-model="myObj.subject"
                rows="4"
              ></b-form-textarea>
            </b-form-group>
          </b-col> -->

          <b-col md="12">
            <b-form-group
              label="Subject"
              invalid-feedback="Subject is required."
              ref="subject"
            >
              <b-form-input
                placeholder="Enter subject name"
                ref="subject"
                v-model="myObj.subject"
              />
            </b-form-group>
          </b-col>

          <b-col md="12" class="mb-1 d-flex">
            <b-button
              :variant="myObj.isOptional ? 'outline-primary' : 'primary'"
              class="mr-1"
              style="width: 50%"
              @click="myObj.isOptional = false"
            >
              <span>Mandatory</span>
            </b-button>
            <b-button
              :variant="myObj.isOptional ? 'primary' : 'outline-primary'"
              @click="myObj.isOptional = true"
              style="width: 50%"
            >
              <span>Optional</span>
            </b-button>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1 fullWidth"
              @click="Add()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-if="request == false"> {{ sidebarButton }} </span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-modal
      id="modal-login"
      hide-footer
      centered
      no-close-on-backdrop
      title="Add Subject"
      size="lg"
    >
      <b-row class="mt-1">
        <!-- <b-col md="12" v-if="adding">
            <b-form-group
              label="Subject *"
              invalid-feedback="Subject is required."
              ref="subject"
            >
              <b-form-textarea
                ref="subject"
                placeholder="Enter subjects seperated by comma"
                v-model="myObj.subject"
                rows="4"
              ></b-form-textarea>
            </b-form-group>
          </b-col> -->

        <b-col md="6">
          <b-form-group
            label="Subject"
            invalid-feedback="Subject is required."
            ref="subject"
          >
            <b-form-input
              placeholder="Enter subject name"
              v-model="myObj.subject"
            />
          </b-form-group>
        </b-col>

        <b-col md="6" class="d-flex align-items-center">
          <b-button
            :variant="myObj.isOptional ? 'outline-primary' : 'primary'"
            class="mr-1"
            style="width: 50%"
            @click="myObj.isOptional = false"
          >
            <span>Mandatory</span>
          </b-button>
          <b-button
            :variant="myObj.isOptional ? 'primary' : 'outline-primary'"
            @click="myObj.isOptional = true"
            style="width: 50%"
          >
            <span>Optional</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row v-for="(item, ind) in tObj" :key="ind" class="mt-1 mt-md-0">
        <b-col md="4">
          <b-form-group
            label="Teacher"
            invalid-feedback="Teacher is required."
            ref="staff"
          >
            <v-select
              v-model="item.tID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="staffList"
              :reduce="(opt) => opt.id"
              :selectable="(opt) => !selectedTIDs.includes(opt.id)"
              label="name"
              :clearable="false"
              placeholder="Select teacher"
              @input="allowTeachers(item)"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
            label="Fees"
            invalid-feedback="Fees is required."
            ref="fee"
          >
            <b-form-input
              ref="fee"
              placeholder="Enter fee amount"
              v-model="item.subjectFee"
              type="number"
              @keyup="reportDomain == 'myskoolskh' && getShare(item)"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
            :label="
              reportDomain == 'myskoolskh'
                ? 'Teacher share' + showShare(item)
                : 'Teacher share'
            "
            invalid-feedback="Teacher share is required."
            ref="share"
          >
            <b-form-input
              ref="share"
              placeholder="Enter share"
              v-model="item.teacherShare"
              :disabled="reportDomain == 'myskoolskh'"
              type="number"
            />
          </b-form-group>
        </b-col>

        <b-col md="2" class="mt-md-2 pr-0">
          <b-button
            v-if="ind == tObj.length - 1"
            @click="AddSub()"
            variant="primary"
            class="btn-icon rounded-circle mr-50"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
          <b-button
            v-if="tObj.length > 1"
            @click="removeSub(item, ind)"
            variant="outline-danger"
            class="btn-icon rounded-circle"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </b-col>
      </b-row>

      <b-row align-h="end">
        <b-col md="3" class="mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="success"
            class="mr-1"
            @click="Add()"
            :disabled="request"
            block
          >
            <b-spinner v-if="request" small type="grow" />
            <span v-if="request == false"> {{ sidebarButton }} </span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-card>
      <b-row class="mt-1">
        <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
          <b-button
            @click="AddOpen()"
            style="width: 100%"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Add Subject</span>
          </b-button>
        </b-col>

        <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
          <b-button
            @click="openCat()"
            style="width: 100%"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Groups</span>
          </b-button>
        </b-col>

        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
          xl="8"
          lg="6"
          md="4"
          sm="12"
          cols="12"
        >
          <b-form-group class="">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="filters"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="dataLoading"
        show-empty
        responsive
        hover
        :selectable="rights.edit"
        select-mode="single"
        @row-selected="EditNew($event[0].id)"
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(name)="data">
          <b-badge style="margin-inline: 2px" variant="light-primary">
            {{ data.item.name }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="text-center">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-center">
            <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="Edit"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="EditNew(data.item.id)"
            >
              <feather-icon icon="EditIcon" class="" />
            </b-button>

            <b-button
              v-if="rights.delete"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.right
              title="Delete"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Delete(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BFormTextarea,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    // BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,

    BDropdown,
    BDropdownItem,

    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData();
      this.loadStaff();
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filters: function () {
      return this.items.filter((pro) => {
        return pro.subject.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      rights: {},
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,
      color: [
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "primary",
        "secondary",
      ],

      sidebarTitle: "Add Subject",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      logoloading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "Subject", key: "subject" },
        { key: "actions", label: "actions" },
      ],
      items: [],
      selected: null,
      rangeDate: null,
      adding: false,
      myObj: {
        id: 0,
        subject: "",
        campusID: this.$store.state.userData.cId,
      },
      depObj: {
        id: 0,
        name: "",
        campusID: this.$store.state.userData.cId,
      },
      detailObj: {},
      timeout: 10000,
      staffList: [],
      tObj: [],
      selectedTIDs: [],
      reportDomain: this.$store.state.report.replace(/_/g, ""),
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async loadStaff() {
      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "staff/filter?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dptID=0&status=present",
        token: this.$store.state.userData.token,
      });
      this.staffList = myitem;
    },
    allowTeachers(item) {
      this.selectedTIDs = this.tObj.reduce((acc, el) => {
        acc.push(el.tID);
        return acc;
      }, []);
      // console.log(this.selectedTIDs);
      // console.log(item);

      if (item && this.reportDomain == "myskoolskh") {
        this.getShare(item);
      }
    },
    showShare(item) {
      if (item.tID > 0) {
        let tt = this.staffList.find((el) => el.id == item.tID);
        return tt && tt.sharePerc ? ` (${tt.sharePerc}%)` : " (0%)";
      }
      return " (0%)";
    },
    getShare(item) {
      // console.log(item);
      let x = parseInt(item.subjectFee);
      if (item.tID > 0 && !isNaN(x) && x > 0) {
        let tt = this.staffList.find((el) => el.id == item.tID);
        if (tt && tt.sharePerc) {
          item.teacherShare = parseInt(x * (tt.sharePerc / 100));
        } else item.teacherShare = 0;
      } else item.teacherShare = 0;
    },
    AddSub() {
      this.tObj.push({
        id: 0,
        subjectID: 0,
        tID: 0,
        subjectFee: 0,
        teacherShare: 0,
        campusID: this.$store.state.userData.cId,
      });
      // console.log(this.tObj);
    },
    async removeSub(item, ind) {
      if (item.id == 0) {
        this.tObj.splice(ind, 1);
        this.selectedTIDs = this.selectedTIDs.filter((el) => el != item.tID);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "SubjectTeacherShare/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.tObj.splice(ind, 1);
          this.selectedTIDs = this.selectedTIDs.filter((el) => el != item.tID);
        }
      }
    },

    openCat() {
      this.$router.push("/subjects");
    },

    async Edit(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects/GetSelected?id=" +
          id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      // console.log("edit:", this.myObj);
      this.adding = false;
      this.visibility = true;
      this.sidebarTitle = "Edit Subject";
      this.sidebarButton = "Update";
      var elem = this.$refs["subject"];
      elem.state = undefined;
    },

    async EditNew(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects/LoadForCoaching?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&subjectID=" +
          id,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      // console.log(res);
      this.myObj = res.subject;
      this.tObj = res.teachers;
      if (this.tObj.length == 0) {
        this.tObj = [
          {
            id: 0,
            subjectID: 0,
            tID: 0,
            subjectFee: 0,
            teacherShare: 0,
            campusID: this.$store.state.userData.cId,
          },
        ];
        this.selectedTIDs = [];
      } else this.allowTeachers();

      this.adding = false;

      this.$bvModal.show("modal-login");
    },

    AddOpen() {
      this.myObj = {
        id: 0,
        subject: "",
        campusID: this.$store.state.userData.cId,
        isOptional: false,
      };
      this.tObj = [
        {
          id: 0,
          subjectID: 0,
          tID: 0,
          subjectFee: 0,
          teacherShare: 0,
          campusID: this.$store.state.userData.cId,
        },
      ];
      this.selectedTIDs = [];
      this.adding = true;
      // this.visibility = true;
      this.$bvModal.show("modal-login");
      this.sidebarTitle = "Add Subject";
      this.sidebarButton = "Save";
      var elem = this.$refs["subject"];
      if (elem) elem.state = undefined;
    },

    CheckName() {
      var elem = this.$refs["subject"];
      if (this.myObj.subject.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    hideSideBar() {
      this.visibility = false;
    },

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      if (this.items.length != 0) {
        this.totalRows = this.items.length;
      }
      this.dataLoading = false;
      // console.log("subject", this.items);
    },

    CheckValues() {
      let state = true;
      // this.tObj = this.tObj.filter((el) => el.tID != 0);
      this.tObj.forEach((el) => {
        // console.log(
        //   isNaN(el.subjectFee) ||
        //     el.subjectFee < 0 ||
        //     isNaN(el.teacherShare) ||
        //     el.teacherShare < 0 ||
        //     el.teacherShare > 100
        // );
        el.subjectFee = parseInt(el.subjectFee);
        el.teacherShare = parseInt(el.teacherShare);
        if (
          isNaN(el.subjectFee) ||
          el.subjectFee < 0 ||
          isNaN(el.teacherShare) ||
          el.teacherShare < 0
          // || el.teacherShare > 100
        ) {
          state = false;
        }
      });
      return state;
    },
    async Add() {
      this.CheckName();
      if (this.CheckName() == false || this.CheckValues() == false) {
        return this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Subjects/SaveMultipleForCoaching?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: {
            subject: this.myObj,
            teachers: this.tObj,
          },
          message: "Subject added successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        this.visibility = false;
        if (status) {
          this.LoadData();
          this.$bvModal.hide("modal-login");
        }
      }
    },

    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Subjects/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "Subject removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
/* .modal::v-deep .modal-content {
  overflow-y: unset !important;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 3.5rem) !important;
} */
</style>
